import React, {useEffect, useState} from 'react'
import { Styling } from './styled.js'
import {Link} from 'gatsby'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import gsap from 'gsap'
import formatIntructors from '../../../../utils/formatInstructors'

export default function CourseCard({course, index, courseProgress}) {

    
    const [percent, setPercent] = useState(0)
    
    useEffect(() => {
        if(courseProgress !== undefined){
            setPercent(Math.floor(courseProgress[index].percentComplete))
            setTimeout(function(){
                gsap.to(`.course-card-${index} .percent_indicator`, {width:Math.floor(courseProgress[index].percentComplete)+'%'})
            }, 500)
        }
    },[courseProgress])


    

  
    const theImage = getImage(course?.courseBuilder?.courseThumbnailImage?.localFile);
    return (
        <Styling className={`course-card course-card-${index}`}>
            <GatsbyImage image={theImage} className="image" alt={'test'} />
            <div className="info">
                <div className="course-title">{course.title}</div>
                <div className="course-instructor">by {formatIntructors(course)}</div>
                <div className="progress">
                    <div className="percent_bar">
                        <div className="percent_indicator"></div>
                    </div>
                    <div className="percent_complete">{percent}% complete</div>
                </div>
                <div className="buttons">
                    <Link to={`/course-player/${course.slug}`} className={'capsule_button black'}>{percent < 1 ? 'Start course' : 'Resume course' }</Link>
                    {/* <Link to={course.link} className={'capsule_button black'}>{progress < 1 ? 'Start course' : 'Resume course' }</Link> */}
                    <Link to={`/programmes/on-demand/${course.slug}`} className={'capsule_button white'}>Course overview</Link>
                </div>
            </div>
        </Styling>
    )
}
