import React, { useState, useEffect } from "react";
import { Styling } from "../styles/pages/course-dashboard.styled";
import CourseCard from "../components/page-components/CourseDashboard/CourseCard";
import Arrow from "../images/arrow_down.inline.svg";
import { graphql } from "gatsby";
import { isLoggedIn } from "../utils/auth";
import getCourseProgressByCourseID from "../utils/getCourseProgressByCourseID";
import Seo from "../components/global/seo";

export default function CourseDashboard({ data }) {
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [usersCourses, setUsersCourses] = useState([]);
  const [courseProgress, setCourseProgress] = useState();

  async function getCourses(userID, jwt) {
    const url = `${process.env.GATSBY_CMS_URL}/wp-json/toolroom/v1/user/${userID}/courses-completed?jwt=${jwt}`;
    const courses = data.allWpCourse.nodes;

    const subscribedCourses = [];
    const response = await fetch(url);
    const returnedCourses = await response.json();

    Object.entries(returnedCourses.data).forEach((entry) => {
      const courseId = entry;
      courses.map((course) => {
        if (course.databaseId === parseInt(courseId)) {
          subscribedCourses.push(course);
        }
      });
    });
    await setUsersCourses(subscribedCourses);
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const user = JSON.parse(window.localStorage.gatsbyUser);
      const jwt = window.localStorage.userJwt;

      getCourses(user.userID, jwt);
      getCourseProgressByCourseID(user.userID, setCourseProgress);
      setIsLoggedInUser(isLoggedIn());
    }
  }, []);

  // useEffect(() => {
  //   //console.log(courseProgress)
  // },[courseProgress])

  return (
    <Styling>
      <Seo
        title={"Course Dashboard"}
        description={"All your Toolroom Academy courses in one place"}
      />
      {isLoggedInUser ? (
        <div className="course_dashboard">
          <div className="intro">
            <h3 className="title">
              Your courses <Arrow className="dashboard_arrow" />
            </h3>
          </div>
          <div className="course_cards">
            {usersCourses.length > 0
              ? usersCourses.map((course, index) => {
                  return (
                    <CourseCard
                      course={course}
                      key={`course_${index}`}
                      index={index}
                      courseProgress={courseProgress}
                    />
                  );
                })
              : "Loading courses..."}
          </div>
        </div>
      ) : (
        <div className="course_dashboard--not-loggedin">
          You must login to view your courses
        </div>
      )}
    </Styling>
  );
}

export const query = graphql`
  {
    allWpCourse {
      nodes {
        id
        slug
        title
        databaseId
        courseBuilder {
          instructors {
            instructor {
              ... on WpInstructor {
                id
                title
              }
            }
          }
          courseThumbnailImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        link
      }
    }
  }
`;
