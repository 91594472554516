export default async function getCourseProgressByCourseID(
  userID,
  setCourseProgress
) {
  const jwt = window.localStorage.userJwt;
  const url = `${process.env.GATSBY_CMS_URL}/wp-json/toolroom/v1/user/${userID}/courses-completed?jwt=${jwt}`;
  const response = await fetch(url);
  const returnedCourses = await response.json();

  let courseProgressArray = [];

  // ============================================================
  // Loop over all returned courses
  // ============================================================
  Object.entries(returnedCourses.data).forEach((entry, index) => {
    const courseId = entry;
    // ============================================================
    // Loop over all object rows and remap multi-array to
    // ============================================================

    let lessonsCompleteCount = 0;
    let totalLessonsCount = 0;
    let percentCompleted = 0;
    Object.entries(entry[1]).forEach((chapter, index) => {
      Object.entries(chapter[1]).forEach((lessons, index) => {
        if (lessons[1] === true) {
          lessonsCompleteCount = lessonsCompleteCount + 1;
        }

        totalLessonsCount = totalLessonsCount + 1;
        percentCompleted = (lessonsCompleteCount / totalLessonsCount) * 100;
      });
    });
    courseProgressArray[index] = { percentComplete: percentCompleted };
  });

  setCourseProgress(courseProgressArray);

  // //console.log('currentCourseProgress', currentCourseProgress)
}
