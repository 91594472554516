
import styled from 'styled-components'

const Styling = styled.div`
box-shadow: 0px 16px 47px rgba(0, 0, 0, 0.05), 0px 8.1px 20.4891px rgba(0, 0, 0, 0.03375), 0px 3.2px 7.6375px rgba(0, 0, 0, 0.025), 0px 0.7px 2.71719px rgba(0, 0, 0, 0.01625);
border-radius: 0.8rem;
overflow: hidden;

    .percent_complete{
    font-size: 1.2rem;
    margin-bottom: 2rem;
    }

    .percent_bar{
    width: 100%;
    height:4px;
    background: var(--grey);
    border-radius: 2px;
    margin-bottom: 1rem;

        .percent_indicator{
        width:0%;
        height: 100%;
        background: var(--black);
        }
    }

    .info{
    width: 100%;
    padding:4rem 2rem;

        @media only screen and (min-width: 1024px) {
        padding:4rem;
        }

        .course-title{
        text-transform:uppercase;
        font-size:2.4rem;
        margin-bottom: 2rem;
        ${({theme}) => theme.tomato('regular')}  
        letter-spacing: -0.15rem;
        }

        .course-instructor{
        font-size:clamp(1.2rem, 0.8vw, 1.8rem);
        margin-bottom: 2rem;
        }

    }

    .buttons{
    width: 100%;

        .capsule_button{
        width: 100%;
        text-align: center;
        justify-content: center;
        
            &:first-child{
            margin-bottom: 1rem;
            }

        }

    }

`

export { Styling }