
import styled from 'styled-components'

const Styling = styled.div`
background:var(--white);

    .intro{
    position: relative;
    width: 100vw;
    background:var(--white);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding:8rem 2rem;

        @media only screen and (min-width: 350px) {
        padding:10rem 4rem 0 4rem;
        }


        @media only screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        padding:10rem 4rem 0 4rem;
        }

        @media only screen and (min-width: 1200px) {
        padding:10vw 10vw 0rem 10vw;
        }


        .title{
        ${({theme}) => theme.tomato('regular')}  
        text-transform:uppercase;
        /* font-size:clamp(6rem, 6vw, 12rem);
        line-height: clamp(6rem/1.15, 6vw/1.15, 12rem/1.15); */
        font-size:3rem;
            line-height:3rem;
                
                @media only screen and (min-width: 350px) {
                font-size:3.5rem;
                line-height:3rem;
                letter-spacing: -0.2rem;
                }

                @media only screen and (min-width: 420px) {
                font-size:4rem;
                line-height:4rem;
                letter-spacing: -0.3rem;
                }
                
                @media only screen and (min-width: 610px) {
                font-size:5rem;
                line-height:5rem;
                letter-spacing: -0.4rem;
                }
                
                @media only screen and (min-width: 800px) {
                    font-size:clamp(6rem, 6vw, 12rem);
                 line-height: clamp(6rem/1.15, 6vw/1.15, 12rem/1.15);
                }
        /* margin-bottom: clamp(6rem/2, 6vw/2, 20rem/2); */

            .dashboard_arrow{
            width: 0.7em;
            height: 0.7em;

                path{
                fill:var(--black);
                }
            }
        }
    }

    .course_cards{
    position: relative;
    width: 100vw;
    background:var(--white);
    padding:8rem 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;

        @media only screen and (min-width: 350px) {
        padding:4rem 4rem 8rem 4rem;
        }

        @media only screen and (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        padding:8rem 4rem;
        }

        @media only screen and (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 4rem;
        padding:8rem 10vw 10vw 10vw;
        }

    }
`

export { Styling }